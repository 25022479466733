import React from 'react'
  import { MDXTag } from '@mdx-js/tag'


  import DefaultLayout from "/opt/build/repo/src/templates/page.jsx";
import { map } from '../../config/map';
import { Map } from '../components/Map';
const layoutProps = {};
export default class MDXContent extends React.Component {
  constructor(props) {
    super(props);
    this.layout = DefaultLayout;
  }

  render() {
    const {
      components,
      ...props
    } = this.props;
    return <MDXTag name="wrapper" Layout={this.layout} layoutProps={Object.assign({}, layoutProps, props)} components={components}>


      <Map center={[48.720384, 21.2538303]} bounds={[[map.lat2, map.lng1], [map.lat1, map.lng2]]} />
      <MDXTag name="p" components={components}>{`Dáta pochádzajú z projektu `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.openstreetmap.org/"
        }}>{`Open Street Map`}</MDXTag>{`. Chýba niečo? `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "mailto:info@ppke.sk"
        }}>{`Ozvi sa nám`}</MDXTag>{` alebo `}<MDXTag name="a" components={components} parentName="p" props={{
          "href": "https://www.openstreetmap.org/edit"
        }}>{`edituj informácie`}</MDXTag>{` priamo v projekte.`}</MDXTag>
      {
        /* <small>Naposledy aktualizované ...</small> */
      }
           </MDXTag>;
  }

}
MDXContent.isMDXComponent = true;
export const _frontmatter = {};
      